/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}

ion-icon {
    font-size: 32px;
}

body {
    background-color: #1D7EFF;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23000' stroke-width='66.7' stroke-opacity='0.05' %3E%3Ccircle fill='%231D7EFF' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%233475f0' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23406ce1' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%234763d2' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%234b5bc4' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%234e52b5' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%234f4aa7' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%234e429a' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%234c3b8c' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%234a337f' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%23462c72' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23422665' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%233e1f59' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%2338194e' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23331343' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%232d0d38' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%2326082e' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23210024' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.ion-page {
  justify-content: flex-start;
}


//Dialog Modal
ion-modal {
  --height: 400px;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}
ion-modal ion-toolbar {
  --background: white;
  //--color: white;
}

